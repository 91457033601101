<template>
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="form-group col-sm-6">
          <label
            for="originalLocation"
            class="label-require"
          >Original Location</label>

          <div class="input-group">
            <span class="input-group-text">
              <div
                v-if="$data.ajax.originalLocationLoad"
                class="spinner-border spinner-border-sm text-secondary"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <i
                v-else
                class="fas fa-plane-departure text-danger"
              />
            </span>
            <input
              id="originalLocation"
              type="text"
              class="form-control"
              placeholder="Original Location"
              autocomplete="off"
            >
          </div>
          <ErrorFormField :msg-array="$data.errorFields.originalLocation" />
          <input
            id="origin_lat"
            type="hidden"
          >
          <input
            id="origin_lon"
            type="hidden"
          >
          <input
            id="origin_location_country_code"
            type="hidden"
          >
        </div>
        <div class="form-group col-sm-6">
          <label
            for="originalLocation"
            class="label-require"
          >Destination Location</label>
          <div class="input-group">
            <span class="input-group-text">
              <LoadingIcons
                v-if="$data.ajax.destinationLocationLoad"
                total="1"
              />
              <i
                v-else
                class="fas fa-plane-arrival text-danger"
              />
            </span>
            <input
              id="destinationLocation"
              type="text"
              class="form-control"
              placeholder="Destination Location"
              autocomplete="off"
            >
          </div>
          <ErrorFormField :msg-array="$data.errorFields.desinationLocation" />
          <input
            id="destination_lat"
            type="hidden"
          >
          <input
            id="destination_lon"
            type="hidden"
          >
          <input
            id="destination_location_country_code"
            type="hidden"
          >
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label class="control-label label-require">No. of Flight Stops </label>
          <select
            id="noofstops"
            class="form-control"
            name="noofstops"
          >
            <option value="">
              Select stops
            </option>
            <option value="0">
              Direct
            </option>
            <option value="1">
              1
            </option>
            <option value="2">
              2
            </option>
            <option value="3">
              3
            </option>
            <option value="4">
              4
            </option>
            <option value="5">
              5
            </option>
            <option value="6">
              6
            </option>
          </select>
          <ErrorFormField :msg-array="$data.errorFields.noOfStop" />
        </div>
        <div class="form-group col-sm-6">
          <label class="control-label label-require">
            No of Passengers who need companionship 2
          </label>
          <!--          <input-->
          <!--            type="number"-->
          <!--            class="form-control"-->
          <!--            placeholder="No of Passengers"-->
          <!--            id="passengers_count"-->
          <!--            name="passengers_count"-->
          <!--            value=""-->
          <!--          />-->
          <select
            id="passengers_count"
            class="form-control"
            name="passengers_count"
          >
            <option
              value=""
              disabled
            >
              Select No of Passengers
            </option>
            <option
              v-for="n in 10"
              :key="'stop' + n"
              :value="n"
            >
              {{ n }}
            </option>
          </select>
          <ErrorFormField :msg-array="$data.errorFields.noOfPassenger" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="form-group col-sm-12 text-end">
          <LoadingIcons
            v-if="$data.ajax.getRate"
            total="3"
          />
          <button
            v-else
            id="getRatePeople"
            class="btn btn-primary"
            @click="getRate"
          >
            Get rate
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="circle-price">
        <div class="fs-1 currency-symbol">
          {{ $store.state.currencySymbol }}
        </div>
        <div
          id="currencyTotal"
          class="fs-4 currency-total"
        >
          {{ $data.currencyTotal }}
        </div>
        <div
          id="currencyCode"
          class="fs-1 currency-code"
        >
          {{ $store.state.currencyCode }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyAutocomplete from '../../search/MyAutocomplete';
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';

let autocomplete = require('autocompleter');
export default {
  name: 'People',
  components: { LoadingIcons, ErrorFormField },
  data: function () {
    return {
      ajax: {
        originalLocationLoad: false,
        destinationLocationLoad: false,
        getRate: false,
      },
      errorFields: {
        originalLocation: [],
        desinationLocation: [],
        noOfStop: [],
        noOfPassenger: [],
      },
      currencyTotal: '',
      previousCity: '',
    };
  },

  created() {
    document.title = 'Trepr - People | Service Rate Calculations | Flight Companion';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Are you looking for a flight companion? Select the location & calculate the service rate for your flight companion internationally.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion,  Flight companionship, Flight companion for elders, Earn while traveling, Travel and Earn'
      );
  },
  mounted() {
    let self = this;
    let inputOriginalLocation = document.getElementById('originalLocation');
    let inputDestinationLocation = document.getElementById('destinationLocation');
    // MyAutocomplete.initData(this);
    // this.$data.ajax.originalLocationLoad
    // let objCommonAutocomplete =
    autocomplete({
      input: inputOriginalLocation,
      minLength: 2,
      disableAutoSelect: true,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'original'),
      className: MyAutocomplete.classNameAuto(),
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'original'),
      onSelect: function (item) {
        console.log('original : ', item);
        let tmpCoordinates = item.coordinates;
        document.getElementById('origin_lat').value = tmpCoordinates.lat;
        document.getElementById('origin_lon').value = tmpCoordinates.lon;
        document.getElementById('origin_location_country_code').value = item.country_code;
        inputOriginalLocation.value = '(' + item.code + ') ' + item.name;
      },
    });
    // let objCommonAutocomplete =
    autocomplete({
      input: inputDestinationLocation,
      minLength: 2,
      fetch: (text, update) => MyAutocomplete.fetchAuto(text, update, self, 'destination'),
      className: MyAutocomplete.classNameAuto(),
      render: (item, currentValue) => MyAutocomplete.renderAuto(item, currentValue, self),
      customize: (input, inputRect, container, maxHeight) =>
        MyAutocomplete.customizeAuto(input, inputRect, container, maxHeight, self, 'destination'),
      onSelect: function (item) {
        console.log('destination : ', item);
        let tmpCoordinates = item.coordinates;
        inputDestinationLocation.value = '(' + item.code + ') ' + item.name;
        document.getElementById('destination_lat').value = tmpCoordinates.lat;
        document.getElementById('destination_lon').value = tmpCoordinates.lon;
        document.getElementById('destination_location_country_code').value = item.country_code;
      },
    });
  },
  methods: {
    getRate: async function () {
      this.$data.errorFields.originalLocation = [];
      this.$data.errorFields.desinationLocation = [];
      this.$data.errorFields.noOfStop = [];
      this.$data.errorFields.noOfPassenger = [];
      let originalLocation = document.getElementById('originalLocation').value;
      let destinationLocation = document.getElementById('destinationLocation').value;
      let noofstops = document.getElementById('noofstops').value;
      let passengers_count = document.getElementById('passengers_count').value;
      let errorOriginal = [],
        errorDestinaion = [],
        errStop = [],
        errPass = [];
      if (originalLocation == '') {
        errorOriginal.push('This field is required.');
      }
      if (destinationLocation == '') {
        errorDestinaion.push('This field  is required.');
      }
      if (noofstops == '') {
        errStop.push('This field  is required.');
      }
      if (isNaN(passengers_count)) {
        errPass.push('The value of this field should be a number');
      }

      if (passengers_count == '') {
        errPass.push('This field  is required.');
      }
      if (
        errorOriginal.length > 0 ||
        errorDestinaion.length > 0 ||
        errStop.length > 0 ||
        errPass > 0
      ) {
        if (errorOriginal.length > 0) {
          this.$data.errorFields.originalLocation = errorOriginal;
        }
        if (errorDestinaion.length > 0) {
          this.$data.errorFields.desinationLocation = errorDestinaion;
        }
        if (errStop.length > 0) {
          this.$data.errorFields.noOfStop = errStop;
        }
        if (errPass.length > 0) {
          this.$data.errorFields.noOfPassenger = errPass;
        }

        return;
      }
      this.$data.ajax.getRate = true;
      let origin_lat = document.getElementById('origin_lat').value;

      let origin_lon = document.getElementById('origin_lon').value;
      let origin_location_country_code = document.getElementById(
        'origin_location_country_code'
      ).value;
      let destination_lat = document.getElementById('destination_lat').value;
      let destination_lon = document.getElementById('destination_lon').value;
      let destination_location_country_code = document.getElementById(
        'destination_location_country_code'
      ).value;

      // let currency = document.getElementById('selectCurrency');
      let currency_code = this.$store.state.currencyCode;
      // var currencySymbol = currency.options[currency.selectedIndex].getAttribute('data-symbol');
      let data = {
        origin_lat: origin_lat,
        origin_lon: origin_lon,
        origin_location_country_code: origin_location_country_code,
        destination_lat: destination_lat,
        destination_lon: destination_lon,
        destination_location_country_code: destination_location_country_code,
        noofstops: noofstops,
        passengers_count: passengers_count,
        currency_code: currency_code,
      };

      let role = 'seeker';
      if (
        this.$store.state.currentRole != undefined &&
        this.$store.state.currentRole == 'traveller'
      ) {
        role = 'traveller';
      }

      data.role = role;

      // console.log('data ', data);
      // return;
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      // console.log('form data ', formData);
      let apiUrl = process.env.VUE_APP_API_URL;

      let self = this;
      await fetch(apiUrl + 'price/get-service-rate-people', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          // console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            console.log('get data error for service rate people  ', messages);
            return false;
          }
          self.$data.currencyTotal = resData.convertedPrice;
          self.$data.ajax.getRate = false;
          // self.$store.dispatch('loginUpdate', resData)
          // console.log('get data success for service rate people : ', resData);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  },
};
</script>

<style scoped></style>
